import { SiteLocale } from "src/__generated__/graphql";
import type { IubendaCookieSolutionBannerConfigInterface } from "src/components/Iubenda/IubendaCookieSolutionBanner";

export const IUBENDA_COOKIE_POLICY_ID: Record<SiteLocale, number> = {
	de: 68283112,
	en: 72716104,
};

// https://www.iubenda.com/en/help/1205-how-to-configure-your-cookie-solution-advanced-guide
const iubendaConfiguration = (
	locale: SiteLocale,
): IubendaCookieSolutionBannerConfigInterface => ({
	siteId: 3738088,
	cookiePolicyId: IUBENDA_COOKIE_POLICY_ID[locale],
	lang: locale,
	localConsentDomain:
		typeof window === "undefined"
			? "peerigon.com"
			: window.location.hostname.replace("www.", ""),
	banner: {
		applyStyles: false,
		backgroundOverlay: false,
	},
});

export default iubendaConfiguration;
