import { css } from "@emotion/react";
import { dimensions, breakpoints, transitions } from "./variables";

export const mediaQuery = Object.entries(breakpoints).reduce<{
	[key in keyof typeof breakpoints]: string;
}>(
	(acc, entry) => {
		const [key, value] = entry;

		acc[key as keyof typeof breakpoints] = `@media (min-width: ${value}px)`;

		return acc;
	},
	// removing this cast as eslint suggest breaks the typing
	// eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
	{} as { [key in keyof typeof breakpoints]: string },
);

export const addZoomHoverEffect = (scaleValue: number) => `
  transform: scale(1);
  z-index: ${dimensions.zIndex.default};
  transition: transform ${transitions.regular} 0s, z-index 0s ease 0.2s;

  :hover {
    text-decoration: none;
    transform: scale(${scaleValue});
    transition: transform ${transitions.slow};
    z-index: ${dimensions.zIndex.zoomAnimation};
  }
`;

export const addZoomElementHoverEffect = () => `
  :hover {
    .zoom, .cms-image-wrapper {
      transform: scale(1.1);
      transition: transform 0.4s ease;
    }
  }
`;

export const disableAnchorShadowStyles = `
box-shadow: none;

&:hover,
&:focus {
  box-shadow: none;
  text-decoration: none;
}
`;

export const italic = css`
	font-style: normal;
	font-variation-settings: "slnt" -9.5;
`;
